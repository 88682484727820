import React from 'react'
import MobileCard from './MobileCard'



const Products = ({products}) => {
  

  return (
    <>
    {
       products && products.map(item=>(
            <MobileCard key={item.id} item={item}/>
        ))
    }
    </>
  );
}

export default Products