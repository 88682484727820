import React from 'react'
import './Discount.css'
const Discount = ({item}) => {

  
  if(parseInt(item.CostumerHike + item.PurchaseAmount) > parseInt(20000)){
    const origanalPrice = Math.ceil(item.CostumerHike + item.PurchaseAmount + 3000);
    const discount = ((item.CostumerHike + item.PurchaseAmount + 3000)/3000).toFixed(1);
    const purcheasePrice = item.CostumerHike + item.PurchaseAmount;
    return (<>
        <p>{`₹${origanalPrice}`}</p>
        <p>
          <span>{`₹${purcheasePrice}`}</span>
          <span>{`${discount}% Off`}</span>
        </p>
    </>)
  }
  if(parseInt(item.CostumerHike + item.PurchaseAmount) > parseInt(10000)){
    const origanalPrice = Math.ceil(item.CostumerHike + item.PurchaseAmount + 2000);
    const discount = ((item.CostumerHike + item.PurchaseAmount + 2000)/2000).toFixed(1);
    const purcheasePrice = item.CostumerHike + item.PurchaseAmount;
    return (<>
        <p>{`₹${origanalPrice}`}</p>
        <p>
          <span>{`₹${purcheasePrice}`}</span>
          <span>{`${discount}% Off`}</span>
        </p>
    </>)
  }
  if(parseInt(item.CostumerHike + item.PurchaseAmount) <= parseInt(10000)){
    const origanalPrice = Math.ceil(item.CostumerHike + item.PurchaseAmount + 1000);
    const discount = ((item.CostumerHike + item.PurchaseAmount + 1000)/1000).toFixed(1);

    const purcheasePrice = item.CostumerHike + item.PurchaseAmount;
    return (<>
        <p>{`₹${origanalPrice}`}</p>
        <p>
          <span>{`₹${purcheasePrice}`}</span>
          <span>{`${discount}% Off`}</span>
        </p>
    </>)
  }

  
}

export default Discount