import React from 'react'
import './carousel.css'
import { useEffect } from 'react';
import Carousel from "react-material-ui-carousel";


const Carousels = ({img}) => {
    
    useEffect(()=>{
          
    })
  return (
    <>
     <div className='carousel-set'>

        <Carousel className='carouselImage'>
        {
            img && img.map((item,index)=>(             
                  <img key={index} className='imim' src={item} alt="" />
            ))
        }
        </Carousel>

     </div> 
    </>
  )
}

export default Carousels