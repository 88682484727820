import React, { useEffect } from "react";
import "./ShopPhones.css";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazy-load";

const ShopPhones = ({ item }) => {
  const navigate = useNavigate();

  
  let bbcw = item.BBCW.split(',');

  const ever = (value, index, array) => {
    return value === "false";
  };
  let price = item.PurchaseAmount + item.CostumerHike;

  return (
    <div
      key={item.id}
      className="card"
      onClick={() =>
        {window.scrollTo(0,0);
        navigate(`/${item.Company}/${item.Model}/${item.id}/${item.Slug}`);}
      }
    >
      <div>
        <LazyLoad>
          <img src={item.Thumb}  alt="" />
        </LazyLoad>
      </div>
      <div className="cardDes">
        <div>
          <p>{item.Company}</p>
          <p>{item.Model}</p>
          <p>{`(${item.Variant})`}</p>
          <p>{item.Color}</p>
          {bbcw.every(ever) ? (
            <h3 className="onlyPhoneCard">Only Phone available </h3>
          ) : (
            <p>
              <span
                className={bbcw[0] === "true" ? "trueS" : "false"}
                title="Bill Available"
              >
                <ReceiptLongOutlinedIcon className={"true"} /> Bill
              </span>
              <span
                className={bbcw[1] === "true" ? "trueS" : "false"}
                title="Box Available"
              >
                <IndeterminateCheckBoxOutlinedIcon className={"true"} /> Box
              </span>
              <span
                className={bbcw[2] === "true" ? "trueS" : "false"}
                title="Charger Available"
              >
                <ElectricalServicesIcon className={"true"} /> Charger
              </span>
              <span
                className={bbcw[3] === "true" ? "trueS" : "false"}
                title="Warranty Available"
              >
                <WorkspacePremiumIcon className={"true"} /> Warranty
              </span>{" "}
            </p>
          )}

          <p>{`₹${price}`}</p>
        </div>

        {/* mode */}
        <div>
          <p>{`${item.Company} ${item.Model}`}</p>
          <div>
            <p>
              <span>{`(${item.Variant})`}</span> 
              <span>{item.Color}</span> 
            </p>
            <p>{`₹${price}`}</p>
          </div>
          {bbcw.every(ever) ? (
            <h3 className="onlyPhoneCard">Only Phone available </h3>
          ) : (
            <p>
              <span
                className={bbcw[0] === "true" ? "trueS" : "false"}
                title="Bill"
              >
                <ReceiptLongOutlinedIcon className={"true"} /> Bill
              </span>
              <span
                className={bbcw[1] === "true" ? "trueS" : "false"}
                title="Box"
              >
                <IndeterminateCheckBoxOutlinedIcon className={"true"} /> Box
              </span>
              <span
                className={bbcw[2] === "true" ? "trueS" : "false"}
                title="Charger"
              >
                <ElectricalServicesIcon className={"true"} /> Charger
              </span>
              <span
                className={bbcw[3] === "true" ? "trueS" : "false"}
                title="Warranty"
              >
                <WorkspacePremiumIcon className={"true"} /> Warranty
              </span>{" "}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopPhones;
