import React, { Fragment, useState, useEffect, useRef } from "react";
import "./mobile.css";
import SearchIcon from "@mui/icons-material/Search";
import { gsap } from "gsap";
import Profile from "../../images/profile.png";
import ClearIcon from "@mui/icons-material/Clear";
import { useGetMobilesQuery } from "../../Redux/productsReducer";
import { useParams } from "react-router-dom";
import FilterProducts from "../Filter/FilterProducts";
import Products from "./Products.js";

const Mobile = ({ mobile }) => {
  const [searchBox, setSearchBox] = useState("");

  const [products, setProducts] = useState([]);

  const [max, setMax] = useState(null);
  const [min, setMin] = useState(null);
  const [rangePrice, setRangePrice] = useState([min, max]);

  const searchInput = (event) => {
    setSearchBox(event.target.value);
    setProducts(
      mobile &&
        mobile.filter((filt) => {
          return (
            Object.values(filt)
              .toString()
              .toLowerCase()
              .indexOf(searchBox.toLowerCase()) !== -1
          );
        })
    );
  };

  const maxPri = () => {
    let mxPrice =
      mobile &&
      mobile.slice().sort((a, b) => {
        if (
          parseInt(a.PurchaseAmount + a.CostumerHike) <
          parseInt(b.PurchaseAmount + b.CostumerHike)
        ) {
          return 1;
        }
        if (
          parseInt(a.PurchaseAmount + a.CostumerHike) >
          parseInt(b.PurchaseAmount + b.CostumerHike)
        ) {
          return -1;
        }
        return 0;
      });

    setMax(mxPrice && mxPrice[0].PurchaseAmount + mxPrice[0].CostumerHike);
    setMin(
      mxPrice &&
        mxPrice[mxPrice.length - 1].PurchaseAmount +
          mxPrice[mxPrice.length - 1].CostumerHike
    );
  };

  const priceRange = () => {
    setProducts(
      mobile.filter(
        (item) => item.min === rangePrice && item.max === rangePrice
      )
    );
  };

  const highAmt = () => {
    setProducts(
      mobile.slice().sort((a, b) => {
        if (
          parseInt(a.PurchaseAmount + a.CostumerHike) <
          parseInt(b.PurchaseAmount + b.CostumerHike)
        ) {
          return 1;
        }
        if (
          parseInt(a.PurchaseAmount + a.CostumerHike) >
          parseInt(b.PurchaseAmount + b.CostumerHike)
        ) {
          return -1;
        }
        return 0;
      })
    );
  };

  const lowAmt = () => {
    setProducts(
      mobile.slice().sort((a, b) => {
        if (
          parseInt(a.PurchaseAmount + a.CostumerHike) <
          parseInt(b.PurchaseAmount + b.CostumerHike)
        ) {
          return -1;
        }
        if (
          parseInt(a.PurchaseAmount + a.CostumerHike) >
          parseInt(b.PurchaseAmount + b.CostumerHike)
        ) {
          return 1;
        }
        return 0;
      })
    );
  };

  let brand = "";
  let vari = "";

  const fil = (a, key) => {
    return [...new Map(a.map((x) => [key(x), x])).values()];
  };

  if (mobile) {
    brand = fil(mobile, (x) => x.Company);
    vari = fil(mobile, (x) => x.Variant);
  }
  let comp = brand && brand.map((e) => e.Company);

  const search = useRef();
  const navBar = useRef();
  const mode = window.matchMedia("screen and (max-width:600px)");

  const openSearch = () => {
    if (mode.matches) {
      gsap.fromTo(
        search.current,
        { display: "none", opacity: 0, y: "-10px" },
        {
          display: "flex",
          opacity: 1,
          duration: 0.8,
          y: "0px",
          ease: "elastic.out",
        }
      );
    }
  };

  const closeSearch = () => {
    if (mode.matches) {
      gsap.to(search.current, { display: "none", opacity: 0, duration: 0 });
    }
  };

  const option = [
    { brand: "Samsung", bg: "#12279A", color: "white" },
    { brand: "Apple", bg: "#000000", color: "white" },
    { brand: "OnePlus", bg: "#EA2116", color: "white" },
    { brand: "Realme", bg: "#F6C212", color: "black" },
    { brand: "Xiaomi", bg: "#F76501", color: "white" },
    { brand: "Poco", bg: "#F3C902", color: "black" },
    { brand: "Vivo", bg: "#0066AD", color: "white" },
    { brand: "Oppo", bg: "#016830", color: "white" },
    { brand: "Infinix", bg: "#000000", color: "white" },
    { brand: "Tecno", bg: "#025FF0", color: "white" },
    { brand: "Nokia", bg: "#01468F", color: "white" },
    { brand: "Motorola", bg: "#3B87CD", color: "white" },
    { brand: "Itel", bg: "#F10000", color: "white" },
    { brand: "Lava", bg: "#E01453", color: "white" },
    { brand: "Lenovo", bg: "#3B87CD", color: "white" },
    { brand: "Nothing", bg: "#000000", color: "white" },
    { brand: "BlackBerry", bg: "#000000", color: "white" },
    { brand: "Asus", bg: "#000000", color: "white" },
    { brand: "IQOO", bg: "white", color: "black" },
    { brand: "Honor", bg: "#5E5EF0", color: "white" },
    { brand: "Micromax", bg: "#E86821", color: "white" },
    { brand: "Gionee", bg: "#D23D19", color: "white" },
  ];

  const category = (op) => {
    setProducts(mobile && mobile.filter((it) => it.Company === op.brand));
  };

  useEffect(() => {
    setProducts(mobile);
    maxPri(mobile);
  }, [mobile]);

  return (
    <Fragment>
      {/* Nav Bar */}
      <div ref={navBar} className="navbar">
        <nav>
          <img src={Profile} alt="" />
          <h3>Phoneo - 24</h3>
          <p>
            <input
              ref={search}
              type="text"
              value={searchBox}
              onChange={searchInput}
              placeholder="Search..."
            />
            <span onClick={openSearch}>
              <SearchIcon />
            </span>
          </p>
        </nav>
        <div ref={search} className="searchMode">
          <input
            type="text"
            placeholder="Search.."
            value={searchBox}
            onChange={searchInput}
          />
          <span onClick={closeSearch}>
            <ClearIcon />
          </span>
        </div>
      </div>

      {/* Filter Products */}
      <div className="filterProducts">
        {option.map(
          (item) =>
            brand &&
            brand.map((mob, index) =>
              mob.Company === item.brand ? (
                <p
                  onClick={() => category(item)}
                  key={index}
                  style={{
                    color: `${item.color}`,
                    backgroundColor: `${item.bg}`,
                  }}
                >
                  {item.brand}
                </p>
              ) : null
            )
        )}
      </div>

      {/* Slider */}


      {/* Feature Products */}
      <div className="feature">
        <div className="filterProduct">
          <FilterProducts
            rangePrice={rangePrice}
            max={max}
            min={min}
            // category={category}
            priceRange={priceRange}
            highPrice={highAmt}
            lowPrice={lowAmt}
            brand={brand}
            vari={vari}
          />
        </div>
        <div className="products">
          <Products products={products} />
        </div>
      </div>
    </Fragment>
  );
};

export default Mobile;
