import React, { useState, useRef } from "react";
import {
  useGetMobilesQuery,
  useGetProductNameQuery,
  useGetProductspecsQuery,
} from "../../Redux/productsReducer";
import "./mobiledetails.css";
import { gsap } from "gsap";
import Carousels from "../Filter/Carousels";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HomeIcon from '@mui/icons-material/Home';
import { useParams,useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { LinearProgress,Divider,Box,Skeleton } from "@mui/material";
import BBCW from "./BBCW";
import ShopPhones from "./ShopPhones";
import HighlightsSmall from "./HighlightsSmall";
import Discount from "../Filter/Discount";



const MobileDetails = ({ mobile }) => {
  const { id, slug } = useParams();
  const navigate = useNavigate();

  const { data: mobiles } = useGetMobilesQuery();
  const { data: mobileList  } = useGetMobilesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      data: data?.filter((e) => parseInt(e.id) === parseInt(id)),
    }),
  });

  const { data: mobileSepcs ,isError,isFetching,isSuccess  } = useGetProductspecsQuery({ slug });

  // if(isSuccess && Object.keys(mobileDetails).length > 0){
  //   setMobileSlugData(mobileSepcs)
  // }else{
  //   setMobileSlugData(mobileDetails)
  // }

  let mob = "";
  let des = "";
  let speci = "";

  if (mobileList) {
    mob = Object.values(mobileList);
    mob = mob[0].Thumb.split();
  }
  if (mobileSepcs) {
    des = Object.values(mobileSepcs);
    des = des[1];
    mob = mob.concat(des.phone_images);
    speci = des.specifications;
  }

  const [toggle, setToggle] = useState(true);
  const phoneDes = useRef(null);
  const listForm = useRef(null);
  const mode = window.matchMedia("screen and (max-width:768px)");
  const toggleHandler = () => {
    if (toggle === true) {
      gsap.to(phoneDes.current, { height: "unset", duration: 1 });
    } else {
      if(mode.matches){
        window.scroll(0, 400);
      }else{
        window.scroll(0, 200);
      }
      gsap.to(phoneDes.current, { height: "190px", duration: 0.2 });
    }
    setToggle(!toggle);
  };

  // console.log(des)

  return (
    <Fragment>
      {!mobileList ? (
        <LinearProgress />
      ) : (
        <>
          <div className="details">
            <div className="img">
              <Carousels img={mob} />
            </div>

            <div className="phoneDes">
              <div className="phoneTopDes">
                          
                {mobileList &&
                  mobileList.map((item) => (
                    <div key={item.id}>
                      <div>
                        <div>{item.Company}</div>
                        <div>{` ${item.Model} ${item.Variant}`}</div>
                      </div>
                      <div className="btDes">
                        <div>
                          
                          <div>
                            <p>Color</p>
                            <p>{item.Color}</p>
                          </div>
                          <div>
                            <p>Condition</p>
                            <p>{item.Condition}</p>
                          </div>
                        </div>
                        <div>
                        <div>
                            <p>Shop Name</p>
                            <p>{item.ShopName}</p>
                          </div>
                          <div>
                            <p>Address</p>
                            <p>{item.Address}</p>
                          </div>
                        </div>
                      </div>
                      
                      <div>
                        <div className="expenses">
                          <BBCW condi={item.BBCW} />
                        </div>
                        <div className="priceDes">
                          <Discount item={item}/>
                        </div>

                      </div>
                    </div>
                  ))}
                  <div className="animate">
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                  </div>
              </div>

              <div className="phoneTopDesMob">
                {mobileList &&
                  mobileList.map((item) => (
                    <div key={item.id}>
                      <div>
                        <div>{`${item.Company} ${item.Model}`}</div>
                      </div>
                      <div className="btDes">
                        <div>
                          <div>
                            <p>Shop Name</p>
                            <p>{item.ShopName}</p>
                          </div>
                          <div>
                            <p>Storage</p>
                            <p>{item.Variant}</p>
                          </div>
                          <div>
                            <p>Color</p>
                            <p>{item.Color}</p>
                          </div>
                          <div>
                            <p>Condition</p>
                            <p>{item.Condition}</p>
                          </div>
                        </div>
                        <div>
                          <div>
                            <p>Address</p>
                            <p>{item.Address}</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="expenses">
                          <BBCW condi={item.BBCW} />
                        </div>
                        <div className="priceDes">
                          <Discount item={item}/>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="rip-rif" ref={phoneDes}>
                <h3>Highlight</h3>

                {isError ? null : (
                  <>
                    {isFetching === false && isSuccess === true ? (
                      <HighlightsSmall listForm={listForm} Spec={mobileSepcs} />
                    ) : (
                      <Box sx={{ p: 2 }}>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          sx={{
                            height: "10px",
                            width: "85%",
                            p: 0.5,
                            m: "10px auto",
                          }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          sx={{
                            height: "10px",
                            width: "85%",
                            p: 0.5,
                            m: "10px auto",
                          }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          sx={{
                            height: "10px",
                            width: "85%",
                            p: 0.5,
                            m: "10px auto",
                          }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          sx={{
                            height: "10px",
                            width: "85%",
                            p: 0.5,
                            m: "10px auto",
                          }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          sx={{
                            height: "10px",
                            width: "85%",
                            p: 0.5,
                            m: "10px auto",
                          }}
                        />
                        <Divider />
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          sx={{
                            height: "15px",
                            width: "85%",
                            p: 0.5,
                            m: "10px auto",
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}

                <div className="topDetails">
                  
                  <p>
                    <span>os</span>
                    <span>{des.os}</span>
                  </p>
                  <p>
                    <span>dimension</span>
                    <span>{des.dimension}</span>
                  </p>
                </div>
                <div className="bottomDetails">
                  {speci &&
                    speci.map((item, index) => (
                      <div key={index}>
                        <div>{item.title}</div>
                        <div>
                          {item.specs.map((item) => (
                            <div className="sepcsDetails">
                              <p>{item.key}</p>
                              <p>{item.val[0]}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="desToggle" onClick={toggleHandler}>
                {toggle ? (
                  <>
                    <p>
                      <KeyboardArrowDownIcon />
                    </p>
                    <p>See More</p>
                  </>
                ) : (
                  <>
                    <p>
                      <KeyboardArrowUpIcon />
                    </p>{" "}
                    <p>See Less</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="shopPhones">
            <p className="backToHome" onClick={()=>navigate('/')}><HomeIcon/></p>
            <h3>Shop Products </h3>
            <div className="phones">
              {mobiles &&
                mobiles.map((item) => <ShopPhones key={item.id} item={item} />)}
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default MobileDetails;
