import React from 'react';
import './App.css';
import Mobile from './Components/Product/Mobile';
import MobileDetails from './Components/Product/MobileDetails'
import {
  useGetProductNameQuery,
  useGetMobilesQuery,
} from "./Redux/productsReducer";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";


function App() {

  const { data: mobile } = useGetMobilesQuery();
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Mobile mobile={mobile}/>
      ),
    },
    {
      path: "/:shop/:company/:model/:id/:slug",
      element:(<MobileDetails mobile={mobile}/>),
    },
  ]);

  return (<>

  <RouterProvider router={router} />
  </>);
}

export default App;
