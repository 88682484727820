import React from 'react'
import './Highlight.css'
const HighlightsSmall = (props) => {
    function GetDisplay(data) {
        const D1 = data.data.specifications[3].specs[1].val.toString().split(",")
        const D2 = D1[1].split("(")
        const D3 = data.data.specifications[3].specs[0].val.toString().split(",")
        return (D1[0] + " (" + D2[0] + " ) " + D3[0]);
    }

    function GetBattery(data) {
        const B1 = data.data.specifications[11].specs[0].val.toString().split(",")
        return (B1[0])
    }

    function GetSW(data) {
        let Body = data.data.specifications[2].specs[0].val.toString();
        if (Body.includes("x")) {
            let S1 = data.data.specifications[2].specs[0].val.toString().split("x")
            let S2 = S1[2].split("(")
            let W1 = data.data.specifications[2].specs[1].val.toString().split("(")
            let S3 = S2[0].split("m")
            return (S3[0] + "mm & " + W1[0])
        } else {
            return data.data.specifications[2].specs[0].val.toString()
        }
    }

    function GetCam(data) {
        let key = data.data.specifications[6].specs[0].key
        let Skey = data.data.specifications[7].specs[0].key
        let Front = "";
        let Back = "";

        if (Skey === "Single") {
            let Cam1 = data.data.specifications[7].specs[0].val.toString().split(",")
            Front = Cam1[0]
        }

        if (Skey === "Dual") {
            let Line = data.data.specifications[7].specs[0].val.toString().split("\n")
            let Cam1 = Line[0].split(",")
            let Cam2 = Line[1].split(",")
            Front = Cam1[0] + " + " + Cam2[0]
        }

        if (key === "Single") {
            let Cam1 = data.data.specifications[6].specs[0].val.toString().split(",")
            Back = Cam1[0]
        }

        if (key === "Dual") {
            let Line = data.data.specifications[6].specs[0].val.toString().split("\n")
            let Cam1 = Line[0].split(",")
            let Cam2 = Line[1].split(",")
            Back = Cam1[0] + " + " + Cam2[0]
        }

        if (key === "Triple" || key === "Quad") {
            let Line = data.data.specifications[6].specs[0].val.toString().split("\n")
            let Cam1 = Line[0].split(",")
            let Cam2 = Line[1].split(",")
            let Cam3 = Line[2].split(",")
            Back = Cam1[0] + " + " + Cam2[0] + " + " + Cam3[0]
        }
        return Back + " | " + Front
    }



    const CPU = props.Spec.data.specifications[4].specs[1].val.toString()
    const Battery = GetBattery(props.Spec);
    const Display = GetDisplay(props.Spec);
    const SlimWeight = GetSW(props.Spec);
    const Camera = GetCam(props.Spec);
    
    return (
        <div>

                <div className='bg-w pd2' >
                    <div className='pdL4 fs15 pd1' ><li>{Display}</li></div>
                    <div className='pdL4 fs15 pd1' ><li>{Camera}</li></div>
                    <div className='pdL4 fs15 pd1' ><li>{SlimWeight}</li></div>
                    <div className='pdL4 fs15 pd1' ><li>{Battery}</li></div>
                    <div className='pdL4 fs15 pd1' ><li>{CPU}</li></div>
                </div>
           
        </div>
    )
}

export default HighlightsSmall