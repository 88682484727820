import { Fragment } from 'react'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import './BBCW.css'

const BBCW = ({condi}) => {
 
    let caseCase = condi.split(",");
    const ever = (value,index,array)=>{
        return value === 'false';
    }

  return (
    <Fragment>
         {
            caseCase.every(ever)?
                <h3 className="onlyPhone">Only Phone available </h3>
            :(<p>
            <span className={caseCase[0]==='true'? 'trueS':'false'} title="Bill Available"><ReceiptLongOutlinedIcon className={'true'}/> Bill</span>
            <span className={caseCase[1]==='true'? 'trueS':'false'} title="Box Available"><IndeterminateCheckBoxOutlinedIcon className={'true'}/> Box</span>
            <span className={caseCase[2]==='true'? 'trueS':'false'} title="Charger Available"><ElectricalServicesIcon className={'true'}/> Charger</span>
            <span className={caseCase[3]==='true'? 'trueS':'false'} title="Warranty Available"><WorkspacePremiumIcon className={'true'}/> Warranty</span>              
            </p>)
         }
    </Fragment>
  )
}

export default BBCW