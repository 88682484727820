import React,{Fragment, useEffect, useState} from 'react'
import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';




const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' ,color:'#4097FF'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const PriceFilter = ({highPrice,lowPrice,max,min,priceRange,rangePrice}) => {
    
    const [expanded, setExpanded] = useState('panel1');

  
    const expandedChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : true);
    };

    const [value,setValue] = useState([min,max])
    const handleChange = (event, newValue) => {
        setValue(newValue);
        priceRange(newValue);
    };

    useEffect(()=>{
      
    })
  return (
        <Fragment>
        
            <Accordion className="accordion" expanded={expanded === 'panel1'} onChange={expandedChange('panel1')}>
                <AccordionSummary className="accordionSummary" aria-controls="panel1d-content" id="panel1d-header">
                <Typography>PRICE</Typography>
                </AccordionSummary>
                <AccordionDetails className="sliderDetails">
                
                    <Slider
                    className='priceSlider'
                    value={value}
                    onChange={handleChange}
                    step={1000}
                    min={min}
                    max={max}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    size="small"
                    />


                    <div className='priceSequence'>
                    
                        <p>
                            <input type='radio' name='price' onChange={highPrice}/>
                            <span>Highest To Top</span>
                        </p>
                        <p>
                            <input type='radio' name='price' onChange={lowPrice}/>
                            <span>Lowest To Top</span>
                        </p>
                    
                    </div>

                </AccordionDetails>
            </Accordion>
        </Fragment>
  )
}

export default PriceFilter