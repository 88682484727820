import React, { Fragment, useRef, useState } from "react";
import "./filter.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PriceFilter from "./PriceFilter.js";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#4097FF" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function FilterProducts({
  highPrice,
  lowPrice,
  brand,
  vari,
  priceRange,
  max,
  min,
  rangePrice,
  category,
}) {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : true);
  };

  return (
    <Fragment>
      <div className="price">
        <PriceFilter
          highPrice={highPrice}
          lowPrice={lowPrice}
          priceRange={priceRange}
          max={max}
          min={min}
          rangePrice={rangePrice}
        />
      </div>

      <Accordion
        className="accordion"
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          className="accordionSummary"
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography>BRAND</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <div className="brand">
            {brand &&
              brand.map((br,index) => (
                <p key={index}>
                  <input
                    type="checkbox"
                    value={br.Company}
                    // onChange={category()}
                  />
                  <span>{br.Company}</span>
                </p>
              ))}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className="accordion"
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          className="accordionSummary"
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>ROM & RAM</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <div className="brand">
            {vari &&
              vari.map((va,index) => (
                <p key={index}>
                  <input type="checkbox" value={va.Variant} />
                  <span>{va.Variant}</span>
                </p>
              ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}
